import './description.css';
export const appDescription = `
<Grid>
    
  <h1 class="app-title">Как работает FoodFusion?</h1>  
  <p class="app-text">
    Приложение для расчета пропорций ингредиентов при изменении объема рецепта.</br> 
    Вы указываете исходные данные, а система автоматически пересчитывает нужное количество ингредиентов.
  </p>

  <h2 class="app-subtitle">Как пользоваться?</h2>
  <ol class="app-steps">
    <li class="app-step">
      <strong>Добавьте ингредиенты:</strong> Введите названия и количество в граммах.
    </li>
    <li class="app-step">
      <strong>Укажите объем:</strong> В поле «Хочу сделать» введите желаемое количество продукта.
    </li>
    <li class="app-step">
      <strong>Получите расчет:</strong> Пропорции пересчитаются автоматически.
    </li>
  </ol>
</Grid>
`;
