import * as React from 'react';
import {DataGrid, GridCellParams, GridColDef} from '@mui/x-data-grid';
import {useState} from "react";
import {Add} from "@mui/icons-material";
import {Button, TextField} from "@mui/material";
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import {styled} from '@mui/material/styles';
import {appDescription} from "./description";
import './calculator.css';

const columns: GridColDef[] = [
    {
        field: 'ingredients',
        headerName: 'Ингредиенты',
        editable: true,
    },
    {
        field: 'count',
        headerName: 'гр',
        type: 'number',
        editable: true,
        align: 'left',
        headerAlign: 'left',
    },
    {
        field: 'want',
        headerName: 'Надо',
        type: 'number',
        align: 'left',
        headerAlign: 'left',
    },
];

type Row = {
    id: number;
    ingredients: string;
    count: number;
    want: number;
}

let rows: Row[] = [
    {id: 1, ingredients: 'основное тесто', count: 2400, want: 0},
    {id: 2, ingredients: 'копченая паприка', count: 27, want: 0},
    {id: 3, ingredients: 'паприка сладкая', count: 27, want: 0},
    {id: 4, ingredients: 'кайенский', count: 15, want: 0},
    {id: 5, ingredients: 'вода', count: 110, want: 0},
];

const totalIngredients = 'Хочу сделать';

function calculateTotal(rows: Row[], id: number, wantToGet: number): Row {
    if (wantToGet <= 0) {
        return {id: id, ingredients: totalIngredients, count: 0, want: 0};
    }

    const totalCount = rows.reduce((sum: number, row: Row) => sum + row.count, 0);
    // подсчитали тотал, теперь считаем сколько процентов каждый составляет от тотала
    rows.forEach((row: Row) => {
        const currentRatio = row.count / totalCount;
        row.want = Math.round(wantToGet * currentRatio);
    });

    const totalWant = rows.reduce((sum: number, row: Row) => sum + row.want, 0);

    return {id: id, ingredients: totalIngredients, count: wantToGet, want: totalWant};
}


function isTotalRow(row: Row): boolean {
    return row.ingredients === totalIngredients;
}

function getNewRow(id: number): Row {
    return {id: id, ingredients: 'New', count: 0, want: 0};
}


rows = [...rows, calculateTotal(rows, rows.length + 1, 9600)];
const defaultRows = [...rows];

const buttonStyle = {width: '100%', height: '50px'}

export default function Calculator() {
    const [currentRows, setCurrentRow] = useState(rows);

    const onClickAddRow = () => {
        const rows = [...currentRows.slice(0, -1)]
        const newRow = getNewRow(rows.length + 2)


        setCurrentRow([...rows, newRow, currentRows[currentRows.length - 1]]);
    };

    const mySaveOnServerFunction = (updatedRow: Row, originalRow: Row) => {
        const rows = [...currentRows]
        rows.forEach((row, index) => {
            if (row.id === updatedRow.id) {
                rows[index] = updatedRow;
            }
        });

        let totalRow = rows[rows.length - 1];

        if (isTotalRow(updatedRow)) {
            totalRow = calculateTotal(rows.slice(0, -1), totalRow.id, updatedRow.count)
        } else {
            totalRow = calculateTotal(rows.slice(0, -1), totalRow.id, totalRow.count)
        }
        const toSet = [...rows.slice(0, -1), totalRow]

        setCurrentRow(toSet);

        if (isTotalRow(updatedRow)) {
            return toSet[toSet.length - 1]
        }

        return updatedRow
    }

    const handleProcessRowUpdateError = (error: any,) => {
        console.error(error);
    }

    const [numberValue, setNumberValue] = useState<number | undefined>(5);
    const onClickResetToTemplate = () => {
        const rows = []

        const val = numberValue || 0;

        for (let i = 0; i < val; i++) {
            rows.push(getNewRow(i))
        }

        rows.push(calculateTotal(rows, rows.length + 1, 0))

        setCurrentRow(rows);
    }
    const onClickResetToDefault = () => {
        setCurrentRow(defaultRows);
    }


    return (
        <Grid container spacing={2}>
            <Grid size={12} dangerouslySetInnerHTML={{__html: appDescription}}>
            </Grid>
            <Grid style={{display: 'flex', flexDirection: 'row'}}>
                <Grid size={4}>
                    <TextField
                        value={numberValue}
                        onChange={(e) => setNumberValue(e.target.value ? parseInt(e.target.value) : undefined)}
                        id="outlined-number"
                        label="Number"
                        type="number"
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                onClickResetToTemplate();
                            }
                        }}
                    />
                </Grid>
                <Grid size={4}>
                    <Button onClick={onClickResetToTemplate} variant="contained" color="primary" style={buttonStyle}>Создать
                        пустой
                        шаблон</Button>
                </Grid>
                <Grid size={4}>
                    <Button onClick={onClickResetToDefault} variant="contained" color="primary" style={buttonStyle}>По
                        умолчанию</Button>
                </Grid>
            </Grid>
            <Grid size={12} style={{display: 'flex', flexDirection: 'column'}}>
                <DataGrid
                    rows={currentRows}
                    columns={columns}
                    pageSizeOptions={[100]}
                    processRowUpdate={(updatedRow: Row, originalRow: Row) => mySaveOnServerFunction(updatedRow, originalRow)}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    getCellClassName={(params: GridCellParams<any, any, number>) => {
                        if (
                            params.value == null ||
                            params.row.ingredients !== totalIngredients ||
                            params.field !== 'count'
                        ) {
                            return '';
                        }
                        return 'total-cell';
                    }}
                    hideFooter
                />
            </Grid>
            <Grid size={4}>

                <Button onClick={onClickAddRow} variant="contained" color="primary" startIcon={<Add/>}>
                    Добавить строку
                </Button>

            </Grid>

        </Grid>
    );
}
